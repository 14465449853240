import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Divider } from 'semantic-ui-react';
import styled from 'styled-components';

import { LargeHeader } from 'components/ui/Header';
import Link, { RouterLink } from 'components/ui/Link';
import {
  AnalyticsAwareButton,
  ButtonAccent,
  ButtonSecondaryAccent,
} from 'components/ui/button';
import emptyPageUrl from 'components/ui/svg/undraw_no_data_qbuo.svg';
import emptyDashboardUrl from 'components/ui/svg/undraw_options_2fvi.svg';

import commonPropTypes from 'utils/commonPropTypes';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const EmptyDataPageContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: ${({ layout }) => layout};
  align-items: center;
  justify-content: center;
  padding: ${svars.spaceMedium};
`;

function EmptyDataPage({
  i18nHeaderText,
  headerText,
  actionComponent,
  illustrationUrl,
  maxWidth,
  maxHeight,
  layout,
  style,
}) {
  return (
    <EmptyDataPageContainer layout={layout} style={style}>
      <div style={{ textAlign: 'center' }}>
        <img
          style={{
            maxHeight,
            maxWidth,
            marginBottom: layout === 'column' ? svars.spaceLarge : 0,
          }}
          src={illustrationUrl}
          alt="star"
        />
      </div>
      <span
        style={{
          ...(layout === 'row' ? { margin: 0 } : {}),
          maxWidth: svars.textMaxWidth,
          padding:
            layout === 'column'
              ? `0 ${svars.spaceNormal}`
              : `0 0 0 ${svars.spaceLarge}`,
        }}
      >
        <LargeHeader textAlign={layout === 'column' ? 'center' : 'left'}>
          {i18nHeaderText ? (
            <Trans render={capitalizedTranslation} id={i18nHeaderText} />
          ) : (
            headerText
          )}
        </LargeHeader>
        <div
          style={{
            fontSize: svars.fontSizeMedium,
            ...(layout === 'column'
              ? {
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }
              : {}),
          }}
        >
          {actionComponent}
        </div>
      </span>
    </EmptyDataPageContainer>
  );
}

EmptyDataPage.propTypes = {
  i18nHeaderText: commonPropTypes.i18nText,
  headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  actionComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  illustrationUrl: PropTypes.string,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  layout: PropTypes.oneOf(['row', 'column']),
  style: commonPropTypes.style,
};
EmptyDataPage.defaultProps = {
  headerText: null,
  i18nHeaderText: null,
  actionComponent: null,
  illustrationUrl: emptyPageUrl,
  maxHeight: '230px',
  maxWidth: null,
  layout: 'column',
  style: {},
};

function EmptyAnalysisDashboardPage({
  headerText,
  subHeaderContent,
  illustrationUrl,
  onToggleSupportModal,
  viewFacetId,
  ...otherProps
}) {
  return (
    <EmptyDataPage
      {...otherProps}
      illustrationUrl={illustrationUrl}
      i18nHeaderText={headerText}
      actionComponent={
        <span>
          <Trans id={subHeaderContent} />
          <div
            style={{
              textAlign: 'center',
              margin: `${svars.spaceLarge} 0`,
            }}
          >
            <RouterLink to="/facets">
              <AnalyticsAwareButton
                gaCategory="Customer"
                gaAction="Configure"
                gaLabel="From analysis"
                inputComponent={ButtonSecondaryAccent}
                type="button"
                style={{ marginRight: svars.spaceLarge }}
              >
                <Trans id="return-to-analysis" />
              </AnalyticsAwareButton>
            </RouterLink>

            <RouterLink to={`/facets/update/${viewFacetId}`}>
              <AnalyticsAwareButton
                gaCategory="Customer"
                gaAction="Configure"
                gaLabel="From analysis"
                inputComponent={ButtonAccent}
                type="button"
              >
                <Trans id="modify-analysis" />
              </AnalyticsAwareButton>
            </RouterLink>
          </div>
          <Divider />
          <p style={{ textAlign: 'center' }}>
            <Trans>
              Si malgré cela l&apos;analyse reste vide, n&apos;hésitez pas à{' '}
              <Link base onClick={onToggleSupportModal}>
                contacter le support
              </Link>
              .
            </Trans>
          </p>
        </span>
      }
    />
  );
}
EmptyAnalysisDashboardPage.propTypes = {
  ...EmptyDataPage.propTypes,
  subHeaderContent: PropTypes.oneOfType([
    commonPropTypes.component,
    commonPropTypes.i18nText,
  ]),
  onToggleSupportModal: PropTypes.func.isRequired,
  viewFacetId: PropTypes.string.isRequired,
};

EmptyAnalysisDashboardPage.defaultProps = {
  ...EmptyDataPage.defaultProps,
  illustrationUrl: emptyDashboardUrl,
  i18nHeaderText: t`empty-data-page.no-data-in-this-analysis!`,
  subHeaderContent: t`empty-data-page.check-analysis-configuration`,
};

export { EmptyAnalysisDashboardPage };
export default EmptyDataPage;
