import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import * as svars from 'assets/style/variables';

const ClickableSpan = styled.span`
  display: flex;
  max-width: 100%;
  align-items: center;
  padding: ${svars.spaceNormalLarge} 0;
  white-space: nowrap;
  color: ${(props) => (props.disabled ? 'inherit' : svars.colorPrimary)};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  &:hover {
    color: ${(props) => (props.disabled ? 'inherit' : svars.accentColor)};
  }
`;

const EllipsableTextContainer = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

function SortableHeader({ label, onSort, disabled, ascending, isSorted }) {
  let iconName = null;
  if (!disabled && isSorted) {
    iconName = ascending ? 'caret up' : 'caret down';
  }

  return (
    <ClickableSpan disabled={disabled} onClick={!disabled ? onSort : null}>
      <EllipsableTextContainer>{label}</EllipsableTextContainer>
      {!disabled && iconName && (
        <Icon
          style={{
            marginLeft: svars.spaceSmall,
            marginTop: '0.2em',
            opacity: 1,
          }}
          name={iconName}
        />
      )}
    </ClickableSpan>
  );
}

SortableHeader.propTypes = {
  disabled: PropTypes.bool,
  ascending: PropTypes.bool,
  onSort: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isSorted: PropTypes.bool,
};

SortableHeader.defaultProps = {
  disabled: false,
  ascending: true,
  isSorted: false,
};

export default SortableHeader;
