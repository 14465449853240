import PropTypes from 'prop-types';

import CopyToClipboard from 'components/ui/CopyToClipboard';
import { FlexCell } from 'components/ui/ManagementList/header';
import { AnalyticsAwareHoverableIconButtonWithTooltip } from 'components/ui/icon/HoverableIcon';

import * as svars from 'assets/style/variables';

const stopEventPropagation = (e) => e.stopPropagation();

function RowActions({ actions, item }) {
  return (
    <FlexCell
      auto
      alignRight
      onClick={stopEventPropagation}
      style={{ margin: 0 }}
    >
      {actions.map(
        (
          {
            help,
            icon,
            danger,
            onClick,
            isCopyToClipboard,
            getCopyableText,
            disabled,
            disabledHelp,
          },
          i
        ) => {
          const buttonIcon = (
            <AnalyticsAwareHoverableIconButtonWithTooltip
              size="large"
              accent="true"
              mouseEnterDelay={600}
              key={`htp-${icon}`}
              position="top center"
              help={help}
              disabledHelp={disabledHelp}
              danger={danger}
              disabled={disabled}
              style={{
                marginRight:
                  i === actions.length - 1 ? 0 : svars.spaceNormalLarge,
              }}
              name={icon}
              onClick={
                onClick
                  ? (e) => {
                      e.stopPropagation();
                      onClick(item);
                    }
                  : null
              }
            />
          );
          return isCopyToClipboard ? (
            <CopyToClipboard
              key={`raca-${icon}`}
              text={() => getCopyableText(item)}
              trigger={buttonIcon}
            />
          ) : (
            buttonIcon
          );
        }
      )}
    </FlexCell>
  );
}
RowActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      help: PropTypes.string,
      icon: PropTypes.string,
      onClick: PropTypes.func,
      isCopyToClipboard: PropTypes.bool,
      getCopyableText: PropTypes.func,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  item: PropTypes.shape({}).isRequired,
};
RowActions.defaultProps = {};

export default RowActions;
