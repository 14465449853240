import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RowActions from 'components/ui/ManagementList/RowActions';
import RowMenu from 'components/ui/ManagementList/RowMenu';
import { FlexCell } from 'components/ui/ManagementList/header';

import * as svars from 'assets/style/variables';

const FlexRow = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ManagementListRowFactory = (
  onDelete,
  rowActions,
  allowDelete = true,
  onRename = null,
  onCopy = null,
  onArchive = null,
  isCopyDisabled = null
) => {
  function CampaignRow({ fields, item }) {
    const menuItems = [
      ...(onRename
        ? [
            {
              content: t`rename`,
              icon: 'edit outline',
              onClick: () => onRename(item),
            },
          ]
        : []),
      ...(onCopy
        ? [
            {
              content: t`copy`,
              icon: 'copy',
              onClick: () => onCopy(item),
              disabled: isCopyDisabled?.(item),
            },
          ]
        : []),
      ...(onArchive
        ? [
            {
              content: item.archived ? t`unarchive` : t`archive`,
              icon: 'archive',
              iconStyle: { color: svars.colorWarning },
              onClick: () => onArchive(item),
              disabled: !allowDelete,
            },
          ]
        : []),
      ...(onDelete
        ? [
            {
              content: t`delete`,
              icon: 'delete',
              iconStyle: { color: svars.colorDanger },
              onClick: () => onDelete(item),
              disabled: !allowDelete,
            },
          ]
        : []),
    ];
    const finalRowActions =
      typeof rowActions === 'function' ? rowActions(item) : rowActions;
    return (
      <FlexRow>
        {fields.map(
          ({
            Cell,
            accessor,
            formatter,
            colorFormatter,
            header,
            getCellProps,
            width,
            centered,
          }) => {
            const value = accessor(item);
            const color = colorFormatter ? colorFormatter(item) : null;
            return (
              <FlexCell
                width={width}
                verticalAlign="middle"
                key={header}
                centered={centered}
              >
                {Cell ? (
                  <Cell
                    value={value}
                    color={color}
                    formatter={formatter}
                    {...getCellProps(item)}
                  />
                ) : (
                  value
                )}
              </FlexCell>
            );
          }
        )}
        <RowActions actions={finalRowActions} item={item} />
        {menuItems.length ? (
          <FlexCell
            disabled={false}
            width={25}
            alignRight
            style={{ marginLeft: svars.spaceSmall }}
          >
            <RowMenu items={menuItems} />
          </FlexCell>
        ) : null}
      </FlexRow>
    );
  }
  CampaignRow.propTypes = {
    item: PropTypes.shape({
      name: PropTypes.string,
      app_route: PropTypes.string,
      id: PropTypes.string,
      create_date: PropTypes.string,
      archived: PropTypes.bool,
    }).isRequired,
    fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };
  return CampaignRow;
};

export default ManagementListRowFactory;
