import { Component } from 'react';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Grid, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { ResponsiveContainer } from 'recharts';

import Header from 'components/ui/Header';
import HelpTooltip from 'components/ui/HelpTooltip';
import Segment from 'components/ui/Segment';
import ExportAsImage from 'components/ui/button/export-as/ExportAsImage';
import HoverableIcon from 'components/ui/icon/HoverableIcon';
import BaseTimeSeriesVisualization from 'components/ui/visualization/BaseTimeSeriesVisualization';
import EmptyDataVisualization from 'components/ui/visualization/EmptyDataVisualization';
import LoadingDataVisualization from 'components/ui/visualization/LoadingDataVisualization';

import commonPropTypes from 'utils/commonPropTypes';
import { capitalize } from 'utils/helpers';

import * as styleVariables from 'assets/style/variables';

const FullWidthHeader = styled(Header)`
  &&& {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
  }
`;

class SentimentVolumeTimeSeriesVisualizations extends Component {
  constructor() {
    super();
    this.state = {
      expandChart: null,
      modalHeaderText: '',
    };
  }

  renderVolumeChart = () => {
    const { data } = this.props;
    return (
      <BaseTimeSeriesVisualization
        data={data}
        tooltipFieldKeys={[
          'date',
          'cumulated',
          'n_polarities',
          'average_sentiment',
        ]}
        childrenAttributes={[
          {
            key: 'cumulated',
            y_id: 0,
          },
          { key: 'n_negative', y_id: 1 },
          { key: 'n_neutral', y_id: 1 },
          { key: 'n_positive', y_id: 1 },
        ]}
        yAxisProps={{
          0: {},
          1: { orientation: 'right' },
        }}
      />
    );
  };

  renderSentimentChart = () => {
    const { data, chartId } = this.props;
    const sentiments = data.map((it) => it.average_sentiment);
    const gradientName = `colorSentiment-${chartId}`;
    return (
      <BaseTimeSeriesVisualization
        data={data}
        sentimentGradientName={gradientName}
        sentiments={sentiments}
        tooltipFieldKeys={['date', 'n_chunks', 'average_sentiment']}
        childrenAttributes={[
          {
            key: 'n_chunks',
            y_id: 0,
          },
          {
            key: 'average_sentiment',
            y_id: 1,
          },
        ]}
        yAxisProps={{
          0: { orientation: 'right' },
          1: { orientation: 'left' },
        }}
      />
    );
  };

  renderChartModal() {
    const { expandChart, modalHeaderText } = this.state;
    return (
      <Modal
        closeIcon
        onClose={() =>
          this.setState({ modalHeaderText: '', expandChart: null })
        }
        open={expandChart != null}
        style={{
          top: styleVariables.spaceMassive,
          bottom: styleVariables.spaceMassive,
          width: '90%',
        }}
      >
        <Modal.Header content={modalHeaderText} />
        <Modal.Content>
          <ResponsiveContainer
            height={1.7 * styleVariables.chartHeight}
            width="100%"
          >
            {expandChart ? expandChart() : <div />}
          </ResponsiveContainer>
        </Modal.Content>
      </Modal>
    );
  }

  renderChart = (chartId, data, renderContent, headerText, loading) => (
    <Grid.Column width={6} stretched>
      <Segment>
        <FullWidthHeader data-testid="analysis-kpi-header">
          {headerText}
          <span
            style={{
              height: 'fit-content',
              display: 'inline-flex',
              justifyContent: 'center',
            }}
          >
            <ExportAsImage
              disabled={loading}
              exportId={chartId}
              exportName={headerText}
              exportAsImageTestId={`overview-export-as-image-icon-${chartId}`}
            />
            <HelpTooltip
              compact
              hoverable
              position="top center"
              mouseEnterDelay={300}
              mouseLeaveDelay={50}
              help={capitalize(t`zoom`)}
              trigger={
                <span
                  style={{ paddingLeft: styleVariables.spaceSmall }}
                  data-testid="zoom-button"
                >
                  <HoverableIcon
                    size="large"
                    name="expand"
                    onClick={() =>
                      this.setState({
                        expandChart: renderContent,
                        modalHeaderText: headerText,
                      })
                    }
                    style={{ margin: 0 }}
                  />
                </span>
              }
            />
          </span>
        </FullWidthHeader>
        {loading ? (
          <LoadingDataVisualization height={styleVariables.chartHeight} />
        ) : (
          <ResponsiveContainer
            id={chartId}
            height={styleVariables.chartHeight}
            width="100%"
          >
            {(data === undefined && <div />) || (data && renderContent()) || (
              <EmptyDataVisualization />
            )}
          </ResponsiveContainer>
        )}
      </Segment>
    </Grid.Column>
  );

  render() {
    const { data, loading } = this.props;
    return (
      <>
        {this.renderChart(
          'tsVolumeChart',
          data,
          this.renderVolumeChart,
          capitalize(t`temporal-evolution-of-volume`),
          loading
        )}

        {this.renderChart(
          'tsSentimentChart',
          data,
          this.renderSentimentChart,
          capitalize(t`temporal-evolution-of-sentiment`),
          loading
        )}
        {this.renderChartModal()}
      </>
    );
  }
}

SentimentVolumeTimeSeriesVisualizations.propTypes = {
  data: commonPropTypes.timeSeries,
  chartId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

SentimentVolumeTimeSeriesVisualizations.defaultProps = {
  data: undefined,
  loading: false,
};

export default SentimentVolumeTimeSeriesVisualizations;
